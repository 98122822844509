import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import './index.css';
import 'react-toastify/dist/ReactToastify.css';
import 'animate.css/animate.min.css';
import Home from './pages/index';
import Tags from './components/HeadTags';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

const router = createBrowserRouter([
	{
		path: '/',
		element: <Home Location='Home' />,
	},
]);

root.render(
	<React.StrictMode>
		<Tags />
		<RouterProvider router={router} />
	</React.StrictMode>
);

