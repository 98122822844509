import { ScalableVectorGraphicLogos } from '@bracketed/branding';
import Links from '../FooterNavigationLinks.json';
import React from 'react';
import { ExternalLink, TargetLink } from './Link';
import AdvertisementCard from './AdCard';
import Select from 'react-select';

interface FooterLink {
	label: string;
	href: string;
	target?: string;
}

interface FooterProps {
	FooterNAME: string;
	FooterLinks: Array<FooterLink>;
}

const NavigationLinks: Array<FooterProps> = Links;

const options = [
	{ value: 'en', label: 'English' },
	{ value: 'es', label: 'Spanish' },
	{ value: 'fr', label: 'French' },
	{ value: 'de', label: 'German' },
	{ value: 'ja', label: 'Japanese' },
	{ value: 'zh', label: 'Chinese' },
	{ value: 'ar', label: 'Arabic' },
	{ value: 'ru', label: 'Russian' },
	{ value: 'pt', label: 'Portuguese' },
	{ value: 'it', label: 'Italian' },
];

export default function BracketedWebsiteFooter() {
	return (
		<footer
			className='footer'
			style={{
				height: '440px',
				borderTopStyle: 'solid',
				borderWidth: '1px',
				borderColor: '#222324',
			}}>
			<div
				className='row'
				style={{
					justifyContent: 'center',
					alignItems: 'center',
					placeItems: 'center',
					marginBottom: '2%',
				}}>
				<AdvertisementCard />
				<div
					style={{
						width: '2%',
					}}></div>
				<AdvertisementCard />
			</div>
			<div
				className='row'
				style={{
					justifyContent: 'center',
				}}>
				<a
					style={{
						width: '15%',
						marginRight: '5%',
						border: 'none',
						color: 'inherit',
						background: 'none',
					}}
					href='https://bracketed.co.uk'>
					<img
						src={ScalableVectorGraphicLogos.TeamBracketedVariantTextLogo}
						alt='Team Bracketed Logo'
						style={{
							marginTop: '15%',
						}}
					/>
				</a>
				{NavigationLinks.map((FooterProps, index: number) => (
					<div style={{ marginRight: '2%', color: '#e3e3e3' }} key={index}>
						<h4>{FooterProps.FooterNAME}</h4>
						<ul>
							{FooterProps.FooterLinks.map((FooterLink, index) => (
								<li key={index}>
									<ExternalLink
										label={FooterLink.label}
										href={FooterLink.href}
										target={
											(FooterLink.target as TargetLink) ??
											TargetLink.SELF
										}
										key={index}
									/>
								</li>
							))}
						</ul>
					</div>
				))}
				<div style={{ marginTop: '2.5%', width: '12%' }}>
					<Select
						options={options}
						styles={{
							control: (baseStyles, state) => ({
								...baseStyles,
								borderColor: '#222324',
								backgroundColor: '#242526',
								color: '#ffffff',
							}),
						}}
						placeholder='Translate this page'
						onChange={(optionSelected: any) => {
							console.log(optionSelected);
						}}
					/>
				</div>
			</div>
			<br />
			<div
				style={{
					textAlign: 'center',
					fontWeight: 'bold',
					color: '#e3e3e3',
				}}>
				Copyright © {new Date().getFullYear()} [Team Bracketed], and its partners.
			</div>
		</footer>
	);
}
