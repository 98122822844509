import { Helmet } from 'react-helmet';
import React from 'react';
import { JSX } from 'react/jsx-runtime';

function Head(props: any): JSX.Element {
	return <Helmet {...props} />;
}

export default function Tags() {
	return (
		<Head>
			<title>Bracketed</title>
			<meta charSet='utf-8' />
			<meta
				itemProp='description'
				content='Bracketed is a multi-use discord bot that basically fits to your needs. Our goal is reducing the need for loads of extra bots in your server. You can thank us later ;)'
			/>
			<meta
				itemProp='og:description'
				content='Bracketed is a multi-use discord bot that basically fits to your needs. Our goal is reducing the need for loads of extra bots in your server. You can thank us later ;)'
			/>
			<meta property='og:title' content='Bracketed' />
			<meta name='viewport' content='width=device-width, initial-scale=1.0' />
			<meta httpEquiv='Content-Type' content='text/html; charset=UTF-8' />
			<meta httpEquiv='Expires' content='1y' />
			<meta httpEquiv='Pragma' content='1y' />
			<meta httpEquiv='Cache-Control' content='1y' />

			<link rel='canonical' href='https://bracketed.co.uk' />
			<meta
				property='og:image'
				content='https://github.com/Bracketed/Branding/blob/main/Banners/LogoBanner.png?raw=true'
			/>
			<meta
				name='twitter:image'
				content='https://github.com/Bracketed/Branding/blob/main/Banners/LogoBanner.png?raw=true'
			/>
		</Head>
	);
}
