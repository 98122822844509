import React, { useState, useEffect } from 'react';
import axios from 'axios';
import BadWordsFilter from 'bad-words';
import { ExternalLink, TargetLink } from './Link';
import LinkedContent from '../components/LinkedContent';

const Filter = new BadWordsFilter({ placeHolder: '#', replaceRegex: /[A-Za-z0-9가-힣_]/g });

interface AdData {
	id?: number;
	redirectionLink: string;
	adOwnerId: string;
	adTitle: string;
	adDescription: string;
}

function TextSetup(text: string) {
	if (text.length > 400) {
		text = text.slice(0, 400) + '...';
	}
	return text;
}

const AdvertisementCard: React.FC<{ adData: AdData | null }> = ({ adData }) => {
	if (!adData) {
		return (
			<div
				style={{
					width: '25%',
					borderStyle: 'dashed',
					borderColor: 'rgba(255, 255, 255, 0.1)',
					justifyContent: 'center',
					alignItems: 'center',
					placeItems: 'center',
					padding: '0.5%',
				}}>
				<a href='https://bracketed.co.uk/redirects/discord' target='_blank' rel='noreferrer'>
					<h1
						style={{
							color: 'rgba(255, 255, 255, 0.3)',
							textDecoration: 'none',
						}}>
						Advertise here!
						<LinkedContent />
					</h1>
				</a>
				<p
					style={{
						color: 'rgba(255, 255, 255, 0.3)',
					}}>
					{TextSetup(
						"Hmm, we couldn't find an ad to put here. However, maybe this could be yours! Contact us at our discord for more info!"
					)}
				</p>
				<ExternalLink
					label='Contact us about your Ad here'
					href='https://bracketed.co.uk/redirects/discord'
					target={TargetLink.NEW_PAGE}
				/>
			</div>
		);
	}

	return (
		<div
			style={{
				width: '25%',
				borderStyle: 'dashed',
				borderColor: 'rgba(255, 255, 255, 0.1)',
				justifyContent: 'center',
				alignItems: 'center',
				placeItems: 'center',
				padding: '0.5%',
			}}>
			<a href={adData.redirectionLink} target='_blank' rel='noreferrer'>
				<h1
					style={{
						color: 'rgba(255, 255, 255, 0.3)',
						textDecoration: 'none',
					}}>
					{adData.adTitle}
					<LinkedContent />
				</h1>
			</a>
			<p
				style={{
					color: 'rgba(255, 255, 255, 0.3)',
				}}>
				{TextSetup(adData.adDescription)}
			</p>
			<ExternalLink
				label='See Ad Owner Here'
				href={`https://discord.com/channels/@me/${adData.adOwnerId}`}
				target={TargetLink.NEW_PAGE}
			/>
		</div>
	);
};

const AdvertisementCardContainer: React.FC = () => {
	const [adData, setAdData] = useState<AdData | null>(null);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await axios.get(
					'https://api.bracketed.co.uk/bracketed/database/advertisements'
				);

				const AdData: AdData = {
					adDescription: Filter.clean(response.data.adDescription),
					adTitle: Filter.clean(response.data.adTitle),
					adOwnerId: response.data.adOwnerId,
					redirectionLink: response.data.redirectionLink,
				};

				setAdData(AdData);
			} catch {
				setAdData(null);
			}
		};

		fetchData();
	}, []);

	return <AdvertisementCard adData={adData} />;
};

export default AdvertisementCardContainer;
