import { Helmet } from 'react-helmet';
import React from 'react';
import { JSX } from 'react/jsx-runtime';

function Head(props: any): JSX.Element {
	return <Helmet {...props} />;
}

interface PageConfig {
	Location: string;
	Description?: string;
}

export default function Tags(RouteConfig: PageConfig) {
	let RouteDesc =
		'Bracketed is a multi-use discord bot that basically fits to your needs. Our goal is reducing the need for loads of extra bots in your server. You can thank us later ;)';
	if (RouteConfig.Description) {
		RouteDesc = RouteConfig.Description;
	}

	return (
		<Head>
			<title>{`${RouteConfig.Location} | Bracketed`}</title>
			<meta charSet='utf-8' />
			<meta itemProp='description' content={RouteDesc} />
			<meta itemProp='og:description' content={RouteDesc} />
			<meta property='og:title' content={`Bracketed | ${RouteConfig.Location}`} />
		</Head>
	);
}
