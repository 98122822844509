import React from 'react';

interface FeatureItem {
	title: string;
	description: React.ReactNode;
}

export default function Feature({ title, description }: FeatureItem) {
	return (
		<div className='feature col col--4'>
			<div>
				<h2>{title}</h2>
				<div>{description}</div>
			</div>
		</div>
	);
}
