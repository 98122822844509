import { cssTransition, toast } from 'react-toastify';

enum NotificationTypeOptions {
	DEFAULT = 'default',
	SUCCESS = 'success',
	INFO = 'info',
	ERROR = 'error',
	WARNING = 'warning',
}

function getIcon(type: NotificationTypeOptions) {
	switch (type) {
		case NotificationTypeOptions.INFO: {
			return (
				<svg width={100} height={20} aria-hidden='true' viewBox='0 0 950 950'>
					<path
						d='M805.89,218.11c-38.16-38.16-82.61-68.13-132.1-89.06C622.53,107.37,568.1,96.38,512,96.38s-110.53,10.99-161.79,32.67  c-49.49,20.93-93.94,50.9-132.1,89.06c-38.16,38.16-68.13,82.61-89.06,132.1C107.37,401.47,96.38,455.9,96.38,512  s10.99,110.53,32.67,161.79c20.93,49.49,50.9,93.94,89.06,132.1c38.16,38.16,82.61,68.13,132.1,89.06  c51.26,21.68,105.69,32.67,161.79,32.67s110.53-10.99,161.79-32.67c49.49-20.93,93.94-50.9,132.1-89.06  c38.16-38.16,68.13-82.61,89.06-132.1c21.68-51.26,32.67-105.69,32.67-161.79s-10.99-110.53-32.67-161.79  C874.02,300.72,844.05,256.27,805.89,218.11z M577.03,750.44H446.97v-50H487V506.78h-31v-50h81v243.66h40.03V750.44z M512,283.56  c29.94,0,54.29,24.36,54.29,54.29c0,29.94-24.35,54.29-54.29,54.29s-54.29-24.35-54.29-54.29  C457.71,307.92,482.06,283.56,512,283.56z'
						fill='#5865f2'
					/>
				</svg>
			);
		}
		case NotificationTypeOptions.SUCCESS: {
			return (
				<svg width={100} height={20} aria-hidden='true' viewBox='0 0 950 950'>
					<path
						d='M863.05,363.69c-19.19-45.37-46.66-86.12-81.64-121.1c-34.98-34.98-75.73-62.45-121.1-81.64  C613.32,141.08,563.42,131,512,131s-101.32,10.08-148.31,29.95c-45.37,19.19-86.12,46.66-121.1,81.64  c-34.98,34.98-62.45,75.73-81.64,121.1C141.08,410.68,131,460.58,131,512s10.08,101.32,29.95,148.31  c19.19,45.37,46.66,86.12,81.64,121.1c34.98,34.98,75.73,62.45,121.1,81.64C410.68,882.92,460.58,893,512,893  s101.32-10.08,148.31-29.95c45.37-19.19,86.12-46.66,121.1-81.64c34.98-34.98,62.45-75.73,81.64-121.1  C882.92,613.32,893,563.42,893,512S882.92,410.68,863.05,363.69z M476.08,651.58c-6.57,6.57-15.47,10.26-24.75,10.26  c-9.28,0-18.19-3.69-24.75-10.26L318.25,543.26l49.5-49.5l83.58,83.58l204.92-204.92l49.5,49.49L476.08,651.58z'
						fill='#5865f2'
					/>
				</svg>
			);
		}
		case NotificationTypeOptions.ERROR: {
			return (
				<svg width={100} height={20} aria-hidden='true' viewBox='0 0 950 950'>
					<path
						d='M863.05,364.69c-19.19-45.37-46.66-86.12-81.64-121.1c-34.98-34.98-75.73-62.45-121.1-81.64  C613.32,142.08,563.42,132,512,132s-101.32,10.08-148.31,29.95c-45.37,19.19-86.12,46.66-121.1,81.64  c-34.98,34.98-62.45,75.73-81.64,121.1C141.08,411.68,131,461.58,131,513s10.08,101.32,29.95,148.31  c19.19,45.37,46.66,86.12,81.64,121.1c34.98,34.98,75.73,62.45,121.1,81.64C410.68,883.92,460.58,894,512,894  s101.32-10.08,148.31-29.95c45.37-19.19,86.12-46.66,121.1-81.64c34.98-34.98,62.45-75.73,81.64-121.1  C882.92,614.32,893,564.42,893,513S882.92,411.68,863.05,364.69z M676.75,628.25l-49.5,49.5L512,562.5L396.75,677.75l-49.5-49.5  L462.5,513L347.25,397.75l49.5-49.5L512,463.5l115.25-115.25l49.5,49.5L561.5,513L676.75,628.25z'
						fill='#5865f2'
					/>
				</svg>
			);
		}
		case NotificationTypeOptions.WARNING: {
			return (
				<svg width={100} height={20} aria-hidden='true' viewBox='0 0 950 950'>
					<path
						d='M805.1,217.9c-38.07-38.06-82.39-67.94-131.75-88.82C622.24,107.46,567.95,96.5,512,96.5s-110.24,10.96-161.35,32.58  c-49.36,20.88-93.68,50.76-131.75,88.82c-38.06,38.07-67.94,82.39-88.82,131.75C108.46,400.76,97.5,455.05,97.5,511  s10.96,110.24,32.58,161.35c20.88,49.36,50.76,93.68,88.82,131.75c38.07,38.06,82.39,67.94,131.75,88.82  c51.11,21.62,105.4,32.58,161.35,32.58s110.24-10.96,161.35-32.58c49.36-20.88,93.68-50.76,131.75-88.82  c38.06-38.07,67.94-82.39,88.82-131.75c21.62-51.11,32.58-105.4,32.58-161.35s-10.96-110.24-32.58-161.35  C873.04,300.29,843.16,255.97,805.1,217.9z M512,727.5c-24.81,0-45-20.19-45-45s20.19-45,45-45s45,20.19,45,45  S536.81,727.5,512,727.5z M557,356.49c0,14.77-0.75,29.71-2.23,44.41l-15.79,157.2c-1.4,13.91-13,24.4-26.98,24.4  s-25.58-10.49-26.98-24.4l-15.79-157.2c-1.48-14.7-2.23-29.64-2.23-44.41V315.2c0-11.41,9.29-20.7,20.7-20.7h48.6  c11.41,0,20.7,9.29,20.7,20.7V356.49z'
						fill='#5865f2'
					/>
				</svg>
			);
		}
		default: {
			return;
		}
	}
}

function notify(msg: string, type?: NotificationTypeOptions) {
	toast(msg, {
		type: type,
		draggable: true,
		transition: cssTransition({
			enter: 'animate__animated animate__backInRight',
			exit: 'animate__animated animate__backOutRight',
		}),
		style: {
			background: 'rgba(27, 27, 29, 0.5)',
			backdropFilter: 'blur(20px)',
		},
		progressStyle: {
			boxShadow: '0px 0px 120px 15px rgba(88,101,242,0.66)',
			backgroundColor: '#5865f2',
			marginBottom: '5px',
			marginLeft: '5px',
			marginRight: '10px',
			borderRadius: '25px',
		},
		icon: () => getIcon(type ?? NotificationTypeOptions.DEFAULT),
	});
}

export { notify as Notification, NotificationTypeOptions, getIcon as GetToastIcon };
