import Links from '../HeaderNavigationLinks.json';
import logo from '../content/BracketedLogo.svg';
import { CSSProperties } from 'react';
import LinkedContent from './LinkedContent';

interface NavLink {
	title: string;
	href: string;
	external: boolean;
	position: string;
}

const NavigationLinks: Array<NavLink> = Links;

export default function BracketedNavigationalHeader() {
	const ContentLeft: Array<NavLink> = [];
	const ContentMiddle: Array<NavLink> = [];
	const ContentRight: Array<NavLink> = [];

	const sectionStyle: CSSProperties = {
		flex: '1',
		display: 'flex',
		justifyContent: 'center',
		marginRight: '10px',
	};

	const TextFixStyle: CSSProperties = {
		marginTop: '6px',
		marginRight: '30px',
	};

	const TextFixStyle2: CSSProperties = {
		marginLeft: '30px',
	};

	NavigationLinks.forEach((NavLink: NavLink) => {
		switch (NavLink.position) {
			case 'left': {
				ContentLeft.push(NavLink);
				break;
			}
			case 'right': {
				ContentRight.push(NavLink);
				break;
			}
			case 'middle': {
				ContentMiddle.push(NavLink);
				break;
			}
		}
	});

	return (
		<header
			style={{
				display: 'flex',
				justifyContent: 'space-between',
				alignItems: 'center',
				height: '25px',
				paddingTop: '15px',
				paddingBottom: '15px',
				paddingLeft: '20px',
				paddingRight: '20px',
				backgroundColor: '#242526',
				borderBottomStyle: 'solid',
				borderWidth: '1px',
				borderColor: '#222324',
				color: 'white',
			}}>
			<div style={{ ...sectionStyle, textAlign: 'left', justifyContent: 'flex-start' }}>
				<img src={logo} style={{ height: '35px' }} alt='logo' />
				<a
					href='https://bracketed.co.uk'
					style={{ ...TextFixStyle, fontWeight: 'bold', marginLeft: '6px' }}
					className='link'>
					Bracketed
				</a>
				{ContentLeft.map((NavLink, index: number) => {
					if (!NavLink.external)
						return (
							<a
								key={index}
								href={NavLink.href}
								className='link'
								style={{ ...TextFixStyle }}>
								{NavLink.title}
							</a>
						);
					return (
						<a
							href={NavLink.href}
							key={index}
							className='link'
							style={{ ...TextFixStyle }}
							rel='noreferrer'
							target='_blank'>
							{NavLink.title}
							<LinkedContent />
						</a>
					);
				})}
			</div>
			<div style={{ ...sectionStyle, textAlign: 'center', justifyContent: 'center' }}>
				{ContentMiddle.map((NavLink, index: number) => {
					if (!NavLink.external)
						return (
							<a
								key={index}
								href={NavLink.href}
								className='link'
								style={{ ...TextFixStyle2 }}>
								{NavLink.title}
							</a>
						);
					return (
						<a
							href={NavLink.href}
							key={index}
							className='link'
							style={{ ...TextFixStyle2 }}
							rel='noreferrer'
							target='_blank'>
							{NavLink.title}
							<LinkedContent />
						</a>
					);
				})}
			</div>
			<div style={{ ...sectionStyle, textAlign: 'right', justifyContent: 'flex-end' }}>
				{ContentRight.map((NavLink, index: number) => {
					if (!NavLink.external)
						return (
							<a
								key={index}
								href={NavLink.href}
								className='link'
								style={{ ...TextFixStyle2 }}>
								{NavLink.title}
							</a>
						);
					return (
						<a
							href={NavLink.href}
							key={index}
							className='link'
							style={{ ...TextFixStyle2 }}
							rel='noreferrer'
							target='_blank'>
							{NavLink.title}
							<LinkedContent />
						</a>
					);
				})}
			</div>
		</header>
	);
}
