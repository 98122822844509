import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { APIGuild } from 'discord-api-types/v10';

function TextSetup(text: string) {
	if (text.length > 400) {
		text = text.slice(0, 400) + '...';
	}
	return text;
}

const ServerCard: React.FC<{ serverData: APIGuild | null }> = ({ serverData }) => {
	console.log(serverData);
	return (
		<div style={{ width: '400px', height: '300px', backgroundColor: '#1b1b1d' }}>
			<img src={serverData?.banner as string} alt='Banner' style={{ width: '100%' }} />
		</div>
	);
};

const GuildCardContainer: React.FC<{ serverId: string }> = ({ serverId }) => {
	const [serverData, setServerData] = useState<APIGuild | null>(null);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await axios.get(
					`https://api.bracketed.co.uk/bracketed/guilds/${serverId}`
				);

				let GuildData: APIGuild = response.data;

				const img = await axios
					.get(
						`https://cdn.discordapp.com/banners/${GuildData?.id}/${GuildData?.banner}.png`
					)
					.then(() => {
						return `https://cdn.discordapp.com/banners/${GuildData?.id}/${GuildData?.banner}.png`;
					})
					.catch(async () => {
						return 'https://github.com/Bracketed/Branding/blob/main/Banners/LogoBanner.png?raw=true';
					});

				GuildData.banner = img;

				setServerData(GuildData);
			} catch {
				setServerData(null);
			}
		};

		fetchData();
	}, []);

	return <ServerCard serverData={serverData} />;
};

export default GuildCardContainer;
