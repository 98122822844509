export default function ConsoleWarn() {
	console.log('%cHalt!', `color: #5865F2; font-size: 72px; font-weight: bold; -webkit-text-stroke: 2px #23529B`);
	console.log(
		[
			'%cIf someone told you to copy/paste something here,',
			'its very likely you are getting tricked into doing something or about to get scammed or token-logged.',
			'For more information check out\n\nhttps://en.wikipedia.org/wiki/Self-XSS',
		].join(' '),
		'font-size: 16px;'
	);
	console.log(
		[
			'%cWhile we do everything in our power to ensure your security,',
			'pasting anything in here could give attackers access to your private information!',
		].join(' '),
		'font-size: 18px; font-weight: bold; color: red;'
	);
}
